<template>
  <el-drawer
    :modal="false"
    :visible.sync="showTranslate"
    :wrapperClosable="false"
    :title="$t('text.SpeechTransferAndranslationSettings')"
    direction="rtl"
    class="translate"
    :before-close="handleClose"
  >
    <div class="top">
      <div class="select-con">
        <p>{{$t('text.subtitleTranslate')}}</p>
        <!-- 用户参会类型 0 参会人员 1 会议助教 2 主持人 101 翻译 401 游客
        是否主流 0 否 1 是
        主屏用户和翻译不可选择 -->
        <el-select
          v-model="selectTranslate"
          placeholder="请选择"
          @change="onChange"
          :disabled="dataType == 101 || isMainStream == 1"
        >
          <el-option
            v-for="item in translateList"
            :key="item.phoneticTranscriptionType"
            :label="item.phoneticTranscriptionTypeName"
            :value="item.phoneticTranscriptionType"
          >
          </el-option>
        </el-select>
      </div>
      <p>{{$t('text.CurrentSpeaker')}}：{{ mainStreamUser }}</p>
    </div>
    <div class="content" id="transContainer">
      <template v-if="wordList && wordList.length > 0">
        <el-timeline ref="timeline">
          <el-timeline-item
            v-for="(item, index) in wordList"
            :key="index"
            :timestamp="`${item.meetingTimeStampShow} ${
              item.isShow ? '' : '(' + item.userCode + ')'
            }`"
            placement="top"
          >
            <p>{{ item.text }}</p>
          </el-timeline-item>
          <!-- <el-timeline-item 
          v-if="isLoding"
          :timestamp="''" placement="top">
            <p>翻译中...</p>
          </el-timeline-item> -->
        </el-timeline>
      </template>
      <div class="loading" v-else>
        <img src="@/assets/meeting-empty.png" alt="" />
        <p>{{$t('text.InTranslation')}}...</p>
      </div>
    </div>
    <div class="bottom">
      <el-button type="info" @click="onShowMeeting">
        {{$t('text.meetingSummary')}}</el-button>
    </div>
  </el-drawer>
</template>
<script>
import { computed } from "vue";
// import { addSuffix } from '@/utils/util.js'
import util from "@/utils/util.js";

import {
  getMeetingTranslate,
  meetingTranslationMinutesList,
} from "@/api/meeting";
import i18n from "../../locales/index.js";

const initArr = [
  {
    phoneticTranscriptionType: "16k_zh",
    phoneticTranscriptionTypeName: "无翻译",
  },
];

export default {
  props: {
    mainStreamUser: String,
    meetingMinutesStatus: Number,
    meetingId: String,
    inAWordObj: Object,
    inAWordASR: String,
    dataType: Number,
    isMainStream: Number,
    phoneticTranscriptionType:String
  },
  data() {
    return {
      showTranslate: false,
      translateList: this.clone(initArr),
      selectTranslate: "16k_zh",
      wordList: [],
      isShow: false,
    };
  },
  watch: {
    inAWordObj: {
      handler(newVal, oldVal) {
        // Handle object changes here
        this.wordList.push({
          meetingTimeStampShow: newVal.meetingTimeStamp,
          text: newVal.text,
          userCode: newVal.userCode,
          isShow: newVal.userCode == oldVal.userCode ? true : false,
        });
      },
      deep: true,
    },
    inAWordASR(newVal, oldVal) {
      this.wordList.push({
        meetingTimeStampShow: this.$parent.formattedTimestamp,
        text: newVal,
        userCode: this.mainStreamUser,
        isShow: true,
      });
    },
    wordList: {
      handler(newVal, oldVal) {
        this.scrollToBottom();
      },
      deep: true,
    },
  },
  created() {},
  methods: {
    onShow() {
      this.showTranslate = true;
      this.getListFun();
      if(this.dataType==101){
        this.selectTranslate=this.phoneticTranscriptionType
      }
      this.getMeetingTranslationMinutesListFun();
    },
    clone(obj) {
      let objClone = JSON.parse(JSON.stringify(obj));
      return objClone;
    },
    getListFun() {
      this.translateList = this.clone(initArr);
      getMeetingTranslate({ MeetingId: this.meetingId }).then((res) => {
        this.translateList = this.translateList.concat(res.translateMeeting);
      });
    },
    onChange() {
      this.getMeetingTranslationMinutesListFun();
      this.$emit("langOk", this.selectTranslate);
    },
    handleClose(done) {
       done();
      // this.$confirm(i18n.tc('text.DoTurnOffSpeechTransliteration'))
      //   .then((_) => {
      //     // this.$emit('closeOk')
      //     done();
      //   })
      //   .catch((_) => {});
    },
    // 获取会议纪要
    getMeetingTranslationMinutesListFun() {
      this.wordList = [];
      let data = {
        MeetingId: this.meetingId,
        LanguageType: this.selectTranslate,
        // IsMainStream: this.isMainStream,
      };
      meetingTranslationMinutesList(data).then((res) => {
        this.$nextTick(() => {
          // let cloneWordList=JSON.parse(JSON.stringify(this.wordList))
          this.wordList = res;
        });
      });
    },
    onShowMeeting() {
      this.$emit("onMeeting");
    },
    // 滚动到底
    scrollToBottom() {
      this.$nextTick(() => {
        var scrollableDiv = document.getElementById("transContainer");
        if (scrollableDiv) {
          scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
p {
  margin: 0 0;
}
.translate {
  bottom: unset;
  left: unset;
  width: 33%;
  height: 100%;
  /deep/.el-drawer {
    width: 100% !important;
    background: #1f2022;
    .el-drawer__header {
      color: #fff;
      font-size: 16px;
    }
    .el-drawer__body {
      padding: 0 20px;
      box-sizing: border-box;
    }
  }
  .top {
    width: 30%;
    height: 80px;
    background: #1f2022;
    position: fixed;
    > p {
      margin-top: 16px;
      color: #ffffffa3;
      font-size: 14px;
    }
  }
  .select-con {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.12);

    > p {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      height: 40px;
      line-height: 40px;
      padding: 0 12px;
      box-sizing: border-box;
      background: rgba(255, 255, 255, 0.12);
      font-size: 16px;
      color: #fff;
    }
    /deep/.el-select {
      flex: 1;
      .el-input__inner {
        background-color: transparent;
        color: #fff;
      }
    }
  }
  .content {
    width: 100%;
    height: 75%;
    // border: 1px solid #fff;
    box-sizing: border-box;
    margin-top: 90px;
    overflow-y: scroll;
    /deep/.el-timeline {
      overflow: hidden;
    }
    /deep/.el-timeline-item__node {
      background-color: #9197a6;
    }
    /deep/.el-timeline-item__tail {
      border-left: 0.01616rem solid #ffffff29;
    }
    /deep/.el-timeline-item__timestamp {
      color: #ffffff7a;
      font-size: 14px;
    }
    /deep/.el-timeline-item__content {
      color: #fff;
    }
  }
  .bottom {
    position: fixed;
    bottom: 0;
    left: 70%;
    width: 30%;
    height: 74px;
    padding-right: 2%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loading {
    width: 100%;
    text-align: center;
    > img {
      width: 80%;
    }
    > p {
      color: #939bb0;
      font-size: 16px;
      margin-top: -50px;
    }
  }
}
</style>