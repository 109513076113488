<template>
  <div :class="['audio-icon-container', { small: size === 'small' }]">
    <div class="audio-level-container">
        <div class="audio-level" :style="audioLevelStyle"></div>
      </div>
    <svg-icon class="audio-icon" :icon="icon" />
  </div>
</template>
  
  <script>
import SvgIcon from "../SvgIcon.vue";
import { mapGetters } from "vuex";
import MicOnIcon from "./MicOnIcon.vue";
import MicOffIcon from "./MicOffIcon.vue";

export default {
  props: {
    userId: String,
    audioVolume: Number,
    isMuted: Boolean,
    size: String,
    isDisabled: Boolean,
  },
  components: {
    SvgIcon,
  },
  computed: {
    ...mapGetters(["userVolumeObj"]),
    currentAudioVolume() {
      if (this.audioVolume !== undefined) {
        return this.audioVolume;
      }
      if (this.userVolumeObj && this.userId) {
        return this.userVolumeObj[this.userId];
      }
      return 0;
    },
    icon() {
      return this.isMuted ? MicOffIcon : MicOnIcon;
    },
    audioLevelStyle() {
      if (this.isMuted || !this.currentAudioVolume) {
        return "";
      }
      return `height: ${this.currentAudioVolume * 4}%`;
    },
  },
};
</script>
  
  <style scoped>
.audio-icon-container {
  position: relative;
  width: 24px;
  height: 24px;
}

.small {
  transform: scale(0.8);
}

.audio-level-container {
  position: absolute;
  top: 2px;
  left: 7px;
  width: 10px;
  height: 14px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  overflow: hidden;
  flex-direction: column-reverse;
  justify-content: space-between;
}

.audio-level {
  width: 100%;
  background-color: #00cc66; /* You can replace this with your desired color */
  transition: height 0.2s;
}

.audio-icon {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
  