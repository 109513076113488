<template>
  <el-dialog
    :title="$t('text.audioSettings')"
    width="40%"
    :center="true"
    :visible.sync="dialogVisible"
    class="setting"
    :close-on-click-modal="false"
  >
    <div class="item-setting">
      <span class="title">{{$t('text.loudspeaker')}}</span>
      <el-select
        v-model="deviceInfo.currentSpeakerId"
        class="select"
        @change="handleSpeakerChange"
        :disabled="true"
      >
        <el-option
          v-for="item in deviceInfo.speakerList"
          :key="item.deviceId"
          :label="item.label"
          :value="item.deviceId"
        ></el-option>
      </el-select>
      <el-slider
        v-model="speakerValue"
        :min="0"
        :max="100"
        :step="1"
        @change="handleSpeakerVolumeChange"
        style="margin-top: 20px"
      ></el-slider>
    </div>
    <div class="item-setting">
      <span class="title">{{$t('text.microphone')}}</span>
      <el-select
        v-model="deviceInfo.currentMicrophoneId"
        class="select"
        @change="handleMicChange"
      >
        <el-option
          v-for="item in deviceInfo.microphoneList"
          :key="item.deviceId"
          :label="item.label"
          :value="item.deviceId"
        >
        </el-option>
      </el-select>
      <!-- <el-slider v-model="microphoneValue"
      @change="handleMicrophoneVolumeChange"
       style="margin-top: 20px"></el-slider> -->
    </div>
    <!-- <div class="item-setting">
      <span class="title">输出</span>
      <div class="mic-bar-container">
        <div
          v-for="(item, index) in new Array(volumeTotalNum).fill('')"
          :key="index"
          :class="['mic-bar', `${volumeNum > index ? 'active' : ''}`]"
        ></div>
      </div>
    </div> -->
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">{{$t('text.cancel')}}</el-button>
      <el-button type="primary" @click="handleSetting">{{$t('text.confirm')}}</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";
// import rtcMixin from '@/components/mixins/rtc.js';
export default {
  //  mixins: [rtcMixin],
  data() {
    return {
      dialogVisible: false,
      volumeTotalNum: 36,

      currentSpeakerId: "",
      currentMicrophoneId: "",
      speakerValue: 36,
    };
  },
  computed: {
    ...mapState({
      deviceInfo: (state) => state.deviceInfo,
      volumeNum: (state) => state.audioVolume,
    }),
  },
  methods: {
    ...mapActions(["setCurrentSpeakerId", "setCurrentMicrophoneId"]),
    showModal() {
      this.dialogVisible = true;
    },
    //获取设备列表
    getDeviceList() {},
    handleCancel() {
      this.dialogVisible = false;
    },
    handleSetting() {
      this.$emit("changeSet", this.deviceInfo.currentMicrophoneId);
      this.dialogVisible = false;
    },
    handleSpeakerChange(deviceId) {
      // this.setCurrentSpeakerId(this.currentSpeakerId);
    },
    handleMicChange() {},
    handleSpeakerVolumeChange(newVolume) {
      // rtc.js setSpeakerVolume方法
      // setSpeakerVolume(newVolume);
      let new_newVolume = 36;
      if (newVolume != 0) {
        new_newVolume = parseFloat((newVolume / 100).toFixed(2));
      }else{
        new_newVolume=0
      }
      // this.speakerValue=
      this.$emit("setSpeakerVolume", new_newVolume);
    },
  },
};
</script>

<style lang="less" scoped>
.item-setting {
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.select {
  width: 100%;
  font-size: 14px;
}
.title {
  display: inline-block;
  margin-bottom: 8px;
  width: 100%;
  color: #4f586b;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
.mic-bar-container {
  display: flex;
  justify-content: space-between;
  .mic-bar {
    width: 3px;
    height: 6px;
    background-color: #d5e0f2;
    &.active {
      background-color: #27c39f;
    }
  }
}
</style>
