<template>
  <div class="meeting" ref="meeting">
    <div class="content">
      <meeting-room
        ref="meetRoom"
        :meetingId="meetingId"
        :sdkAppId="Number(sdkAppId)"
        :userSig="userSig"
        :userId="userId"
        :roomId="roomId"
        :appSecretKey="appSecretKey"
        @meetingInfo="getMeetingInfo"
      ></meeting-room>
    </div>
  </div>
</template>
<script>
import meetingRoom from "@/components/meeting-room.vue";
import { getUrlParam } from "@/utils/utils.js";
import { getMeetingInformation } from "@/api/meeting";
import { mapActions } from "vuex";

export default {
  components: {
    meetingRoom,
  },
  data() {
    return {
      meetingId: this.$route.query.id || "",
      meetingInfo: {},
      sdkAppId: "",
      secretKey: "",
      userSig: "",
      userId: getUrlParam("userId"),
      roomId: "",
      WebSocket: "", //websocket连接
      appSecretKey: {},
    };
  },
  mounted() {
    this.getMeetingInfo();
  },
  destroyed() {
    this.setMeetingInfo({ speechModeStatus: 0 });
  },
  beforeRouteLeave(to, from, next) {
    this.$refs.meetRoom.exitRoom();
    next();
  },
  methods: {
    ...mapActions(["setMeetingInfo"]),
    getMeetingInfo() {
      let token = localStorage.getItem("storage_key");
      getMeetingInformation({ meetingId: this.meetingId }).then((res) => {
        this.meetingInfo = res;
        this.setMeetingInfo(res);

        this.sdkAppId = res.trtcDto.appid;
        this.userSig = res.trtcDto.sig;
        if (!token) {
          this.userId = res.trtcDto.userCode;
        }
        this.roomId = res.trtcDto.room;
        if (res.dataType != 401) {
          let appSecretKeyString = atob(res.appSecretKey);
          this.appSecretKey = JSON.parse(appSecretKeyString);
        }
        this.$refs.meetRoom.updateMeetingInfo(res);
        // this.$refs.meetRoom.$emit('meeting-info-updated',res)
      });
    },
    handleLeave() {
      this.$refs.meetRoom.handleLeave();
      this.$router.push({ name: "home" });
    },
  },
};
</script>
<style lang="less" scoped>
.meeting {
  height: 100vh;
  background: #404245;

  p {
    margin: 0;
  }

  .nav-bar {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #24272d;
    padding: 0 12px;
    box-sizing: border-box;
    position: fixed;
    left: 0;
    top: 0;

    > p {
      width: 33.3%;
    }

    > p:nth-child(2) {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 15px;

      > img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }

    > p:last-child {
      color: #f33;

      text-align: right;
      font-size: 14px;
    }
  }

  .content {
    width: 100%;
    height: 100vh;
    // padding: 0px 0 55px 0;
    box-sizing: border-box;
    background: #404245;
    // display: flex;
    // align-items: center;
  }

  .footer {
    width: 100%;
    height: 55px;
    background: #24272d;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > ul {
      height: 55px;
      align-items: center;
      display: flex;

      li {
        width: 80px;
        text-align: center;
        padding: 8px 0;
        box-sizing: border-box;
        color: rgba(255, 255, 255, 0.76);
        font-size: 12px;

        > img {
          width: 20px;
          height: 20px;
        }
      }
      li:hover {
        background-color: #f64041;
      }
    }

    .footer_extra {
      display: flex;
      align-items: center;
      margin-right: 20px;
    }
  }
}
</style>
