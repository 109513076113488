<template>
  <el-dialog
     :title="$t('text.virtualBackground')"
    width="60%"
    :center="true"
    :visible.sync="dialogVisible"
    class="bac-setting"
    :close-on-click-modal="false"
  >
    <div class="bac-setting-con">
      <!-- <p class="title">背景虚化</p> -->
      <el-row :gutter="12">
        <el-col :span="8" class="card-wrap">
          <div @click="onSelect(1)">
            <div :class="['img-con', active == 1 ? 'active' : '']">
              <img
                src="../../assets/forbid.png"
                alt=""
                class="bg-img"
                style="width: 65px; height: 65px"
              />
              <img
                src="../../assets/active.png"
                alt=""
                class="active-img"
                v-show="active == 1"
              />
            </div>
            <p>{{$t('text.nonuse')}}</p>
          </div>
        </el-col>
        <el-col :span="8" class="card-wrap">
          <div @click="onSelect(2)">
            <div :class="['img-con', active == 2 ? 'active' : '']">
              <img src="../../assets/dim.png" alt="" class="bg-img" />
              <img
                src="../../assets/active.png"
                alt=""
                class="active-img"
                v-show="active == 2"
              />
            </div>
            <p>{{$t('text.FuzzyBackground')}}</p>
          </div>
        </el-col>
        <el-col
          :span="8"
          v-for="item in bgList"
          :key="item.materialId"
          class="card-wrap"
        >
          <div @click="onSelect(item.materialId,item.materialIdUrl)">
            <div
              :class="['img-con', active == item.materialId ? 'active' : '']"
            >
              <img :src="item.materialIdUrl" alt="" class="bg-img" />
              <img
                src="../../assets/active.png"
                alt=""
                class="active-img"
                v-show="active == item.materialId"
              />
            </div>
            <p>{{ item.name }}</p>
          </div>
        </el-col>
      </el-row>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">{{$t('text.cancel')}}</el-button>
      <el-button type="primary" @click="handleSetting">{{$t('text.confirm')}}</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getVirtualBackground } from "@/api/meeting.js";
export default {
  props: {
    meetingId: String,
  },
  data() {
    return {
      dialogVisible: false,
      bgList: [],
      active: 1,
      selectMaterialIdUrl:'',
    };
  },
  methods: {
    getVirtualBackgroundFun(meetingId) {
      getVirtualBackground({ meetingId: meetingId }).then((res) => {
        this.bgList = res;
      });
    },
    showModal(meetingId) {
      this.getVirtualBackgroundFun(meetingId);
      this.dialogVisible = true;
    },
    handleCancel() {
      this.dialogVisible = false;
    },
    onSelect(value,materialIdUrl) {
      this.active = value;
      this.selectMaterialIdUrl = materialIdUrl||'';
    },

    handleSetting() {
      this.$emit('change',{active: this.active,url:this.selectMaterialIdUrl})
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
// .item-setting {
//   width: 100%;
//   &:not(:last-child) {
//     margin-bottom: 20px;
//   }
// }
p {
  margin: 0;
}
.bac-setting {
  &-con {
    width: 100%;
    .title {
      font-size: 16px;
    }
  }
  .card-wrap {
    margin-top: 10px;
    > div {
      .img-con {
        background: #ebedf2;
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        position: relative;
        .bg-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
        .active-img {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 24px;
          height: 24px;
          border-bottom-right-radius: 10px;
        }
      }
      > p {
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
      }
    }
  }
  .active {
    border: 2px solid #3071ff;
    box-sizing: border-box;
  }
}
</style>
