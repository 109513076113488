<template>
    <span class="svg-icon" :class="[customClass]" :style="customStyle" @click="handleClick">
        <component :is="icon" v-if="icon"></component>
        <slot></slot>
    </span>
</template>
<script>
import { computed } from 'vue';
// import { addSuffix } from '@/utils/util.js'
import util from "@/utils/util.js";

export default {
    props: {
        size: [String, Number],
        responseSize: [String, Number],
        customClass: String,
        icon: Object, // Assuming icon is a Vue component
    },
    computed: {
        customStyle() {
            return this.size ? `width: ${util.addSuffix(this.size)}; height: ${util.addSuffix(this.size)};` : '';
        },
    },
    methods: {
        handleClick(event) {
            this.$emit('click', event);
        },
    },
};
</script>
<style scoped>
.svg-icon {
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
          height: 20px;
}
</style>