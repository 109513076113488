/**
 * 从 window.location.href 中获取指定key的value
 * @param {*} key 要获取的 key
 * @returns window.location.href 中指定key对应的value
 * @example
 * const value = getUrlParam(key);
 */
export function getUrlParam(key) {
    const url = decodeURI(window.location.href.replace(/^[^?]*\?/, ''));
    const regexp = new RegExp(`(^|&)${key}=([^&#]*)(&|$|)`, 'i');
    const paramMatch = url.match(regexp);

    return paramMatch ? paramMatch[2] : null;
}

/**
 * 将dom元素全屏
 * @param {dom} element dom元素
    * setFullscreen(document.documentElement) // 整个页面进入全屏
 * setFullscreen(document.getElementById("id")) // 某个元素进入全屏
 */
export function setFullscreen(element) {
    if (element?.requestFullscreen) {
        element.requestFullscreen()
        return true
    } else if (element?.webkitRequestFullscreen) {
        element.webkitRequestFullscreen()
        return true
    } else if (element?.mozRequestFullScreen) {
        element.mozRequestFullScreen()
        return true
    } else if (element?.msRequestFullscreen) {
        element.msRequestFullscreen()
        return true

    }
    return false
}
/**
 * 退出全屏
 */
export function exitFullScreen() {
    if (!document?.fullscreenElement && !document?.webkitFullscreenElement && !document?.mozFullScreenElement) {
        return
    }
    if (document?.exitFullscreen) {
        document?.exitFullscreen()
    } else if (document?.msExitFullscreen) {
        document?.msExitFullscreen()
    } else if (document?.mozRequestFullScreen) {
        document?.mozRequestFullScreen()
    } else if (document?.webkitFullscreen) {
        document?.webkitFullscreen()
    }
}

/**
 * 当前浏览器是否为移动端浏览器
 */
export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);