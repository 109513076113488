import { postMinutesRecevie } from "@/api/meeting";
import { watch } from "less";
import moment from "moment";
const initArr = [
  {
    phoneticTranscriptionType: "16k_zh",
    phoneticTranscriptionTypeName: "中文",
  },
];
export default {
  data() {
    return {
      streamAsr: null,
      stream: null,
      translateList: this.clone(initArr),
      inAWordASR: '',
      // isLoding: true,//翻译加载中
    };
  },
  methods: {
    moment,
    clone(obj) {
      let objClone = JSON.parse(JSON.stringify(obj));
      return objClone;
    },
    initASR() {
      this.streamAsr = null; // 初始化前先赋值为null
      this.streamAsr = new ASR({
        secretKey: this.appSecretKey.SecredKey,
        secretId: this.appSecretKey.SecredId,
        appId: this.appSecretKey.AppId,
        engine_model_type: this.meetingInfo.phoneticTranscriptionType || '16k_zh',
        voice_format: 1,
        hotword_id: '08003a00000000000000000000000000',
        needvad: 1,
        filter_dirty: 1,
        filter_modal: 1,
        filter_punc: 1,
        convert_num_mode: 1,
        word_info: 2,
        audioTrack: this.stream,
        max_speak_time:10000
      });
      this.streamAsr.start();
      this.streamAsr.OnRecognitionStart = (res) => {
      };
      this.streamAsr.OnSentenceBegin = (res) => {
      };
      this.streamAsr.OnRecognitionResultChange = (res) => {
      };
      this.streamAsr.OnSentenceEnd = (res) => {
        // console.log('本地流：一句话结束', res);
        this.inAWordASR = res.result.voice_text_str
        // this.inAWord = res.result.voice_text_str
        //  用户为主流
        if (this.meetingInfo.mainStreamUser == this.userId) {
          //传给后台，中文，inAWord
          let textObj = {
            userCode: this.userId,
            meetingReserveId: this.meetingId,
            type: 0,//类型 0 主流 1 单独翻译
            languageType: '16k_zh',
            content:res.result.voice_text_str,
            addTime: this.moment().format('YYYY-MM-DD HH:mm:ss'),
            meetingTimeStamp: this.timestamp
          }
          postMinutesRecevie(textObj).then(res=>{})
        }
        //  用户为翻译
        if (this.meetingInfo.dataType == 101) {
          // 翻译类型
          let textObj = {
            userCode: this.userId,
            meetingReserveId: this.meetingId,
            type: 1,//类型 0 主流 1 单独翻译
            languageType: this.meetingInfo.phoneticTranscriptionType,
            content: res.result.voice_text_str,
            addTime: this.moment().format('YYYY-MM-DD HH:mm:ss'),
            meetingTimeStamp: this.timestamp
          }
          postMinutesRecevie(textObj).then(res=>{})
        }
      };
      this.streamAsr.OnChange = (res) => {
        // console.log('本地流：识别中', res);
      };
      this.streamAsr.OnRecognitionComplete = (res) => {
        // console.log('本地流：识别结束', res);
        
      };
      this.streamAsr.OnError = (res) => {
        // console.log('本地流：识别失败', res);
      };
    },
    stopASR() {
      if (this.streamAsr) {
        this.streamAsr.stop();
      }
    },
    startLocalUserASR(){
      if(!this.trtc){
        // console.log('trtc 不存在');
        return
      }
      // 判断语音是否打开
      this.stream = this.trtc.getAudioTrack();
      if (this.stream) {
        // console.log('语音已打开');
        this.initASR();
      } else {
        // console.log('语音未打开');
      }
    },
    
  },
  // beforeDestroy() {
  //   this.stopASR();
  // }
}
